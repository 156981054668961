import axios from "axios";

export default {
  /**
   * Requests for Account Actions
   * @param customerId
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  gardener(customerId, payload) {
    return axios.post(`customer/${customerId}/assign_gardener`, payload);
  },

  status(id, payload) {
    return axios.put(`customer/${id}/customer_subscriber_status`, payload);
  },

  chat(payload) {
    return axios.post(`setup_getstream_accounts_with_email`, payload);
  },

  email(payload) {
    return axios.post("user/change_email", payload);
  },

  verify(id) {
    return axios.put(`customer/${id}/verify_email`);
  },

  ibna(id, payload) {
    return axios.put(`customer/ibna/${id}`, payload);
  },

  delete(email, payload) {
    return axios.delete(`customer/${email}`, { data: payload });
  },

  recommendations(payload) {
    return axios.post(
      "updateacustomernextweekmealswithrecommendations",
      payload
    );
  },

  ordersCreate(id, payload) {
    return axios.post(`customers/${id}/orders/all_time`, payload);
  },

  ordersStatus(action, payload) {
    return axios.post(`${action}_orders`, payload);
  },

  ordersPause(payload) {
    return axios.post("pause_orders", payload);
  },

  ordersUnpause(payload) {
    return axios.post("unpause_orders", payload);
  },

  ordersDelete(type, id, payload) {
    const base = `customer_order/${id}`;
    switch (type) {
      case "all":
        return axios.post(`${base}/all`, payload);
      case "some":
        return axios.post(`${base}/bulkdelete`, payload);
      case "specific":
        return axios.post(`${base}/bulkdelete_without_duration`, payload);
      default:
        break;
    }
  },

  orderOneTime(id, payload) {
    return axios.post(`customers/${id}/orders/one_time`, payload);
  },

  /**
   * Requests for Payment Actions
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  charge(payload) {
    return axios.post(`customer/${payload.email}/charge_card`, payload);
  },
};
