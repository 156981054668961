import { render, staticRenderFns } from "./CustomerChatLink.vue?vue&type=template&id=291c03ba&scoped=true&"
import script from "./CustomerChatLink.vue?vue&type=script&lang=js&"
export * from "./CustomerChatLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291c03ba",
  null
  
)

export default component.exports