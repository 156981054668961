<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Customer email"
            prop="customer_email"
            :rules="validateEmail()"
          >
            <el-input type="text" v-model="form.customer_email" readonly />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="linking" @click="link"
        >Link</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerChatLink",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      linking: false,
      form: {
        customer_email: "",
      },
    };
  },
  computed: {
    title() {
      const name = this.formatName(this.customer.name);
      return `Link Chat for ${name}`;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.customer_email = this.stringTrim(this.customer.email);
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    link() {
      this.linking = true;
      const payload = {
        customer_emails: [this.form.customer_email],
      };

      customer
        .chat(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.linking = false;
            this.closeEvent();
          } else {
            this.linking = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.linking = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
